import { FunctionComponent, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { postAnswers } from '@/api/answers';
import { Question, QuestionType } from '@/api/questions/types';
import { AnswerPayload } from '@/api/answers/types';
import { Flow } from '@/api/users/types';
import { useRiskAssessmentQuestionsAndAnswers } from '@/query/queries/risk-assessment';
import { QuestionAnswer } from '@/shared/types/questions.types';
import { HeaderFlow as Header } from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import { StepStatus } from '@/components/ui/progress/progress-steps/progress-steps.types';
import ProgressSteps from '@/components/ui/progress/progress-steps';
import {
	QuestionGroupPanels,
	QuestionGroupPanel,
} from '@/components/feature/questions/question-group-panels';
import {
	RiskAssessmentCurrentQuestions,
	RiskAssessmentStep,
	RiskAssessmentStepAnswers,
} from '@/features/risk-assessment/questionnaire/types';
import { RISK_ASSESSMENT_STEP_MAP } from '@/features/risk-assessment/questionnaire/config';
import { isCurrentFlowFinished } from '@/lib/utils';

const initQuestionnaire: RiskAssessmentStepAnswers = {
	general_investment: {},
	investment_behavior: {},
	emotional_risk_tolerance: {},
};
const initCurrentQuestions: RiskAssessmentCurrentQuestions = {
	general_investment: 0,
	investment_behavior: 0,
	emotional_risk_tolerance: 0,
};

const RiskAssessmentQuestionnaire: FunctionComponent = (): ReactElement => {
	const navigate = useNavigate();
	const scrollElementRef = useRef<HTMLDivElement | null>(null);

	const isFlowFinished = isCurrentFlowFinished(Flow.RISK_ASSESSMENT);

	const [currentStep, setCurrentStep] = useState<RiskAssessmentStep>();
	const [questionnaire, setQuestionnaire] = useState<RiskAssessmentStepAnswers>(initQuestionnaire);
	const [currentQuestions, setCurrentQuestions] =
		useState<RiskAssessmentCurrentQuestions>(initCurrentQuestions);

	// When `currentStep` changes, restart current question for that step
	useEffect(() => {
		setCurrentQuestions(initCurrentQuestions);
	}, [currentStep]);

	const [
		{ data: questions, isFetched: isQuestionsFetched },
		{ data: answers, isFetched: isAnswersFetched },
	] = useRiskAssessmentQuestionsAndAnswers();

	useEffect(() => {
		const generalInvestmentQuestions = questions
			.filter((q) => q.category === RISK_ASSESSMENT_STEP_MAP.general_investment.category)
			.sort((item1, item2) => item1.orderNo - item2.orderNo);
		const investmentBehaviorQuestions = questions
			.filter((q) => q.category === RISK_ASSESSMENT_STEP_MAP.investment_behavior.category)
			.sort((item1, item2) => item1.orderNo - item2.orderNo);
		const emotionalRiskToleranceQuestions = questions
			.filter((q) => q.category === RISK_ASSESSMENT_STEP_MAP.emotional_risk_tolerance.category)
			.sort((item1, item2) => item1.orderNo - item2.orderNo);

		const initializeQuestionAnswer = (acc: QuestionAnswer, curr: Question) => {
			const { type } = curr;
			const { answer } = answers.find((a) => a.question.id === curr.id) || {};

			let initialAnswers: string | string[];
			if (type === QuestionType.OPEN) {
				initialAnswers = answer?.answerText || '';
			} else {
				initialAnswers = answer?.answerIds || [];
			}

			return { ...acc, [curr.id]: initialAnswers };
		};

		const generalInvestment: QuestionAnswer = generalInvestmentQuestions.reduce(
			initializeQuestionAnswer,
			{}
		);
		const investmentBehavior: QuestionAnswer = investmentBehaviorQuestions.reduce(
			initializeQuestionAnswer,
			{}
		);
		const emotionalRiskTolerance: QuestionAnswer = emotionalRiskToleranceQuestions.reduce(
			initializeQuestionAnswer,
			{}
		);

		const currentStep: RiskAssessmentStep = 'general_investment';

		setQuestionnaire({
			general_investment: generalInvestment,
			investment_behavior: investmentBehavior,
			emotional_risk_tolerance: emotionalRiskTolerance,
		});
		setCurrentStep(currentStep);
	}, [questions, answers]);

	const generalInvestmentQuestions = questions
		.filter((q) => q.category === RISK_ASSESSMENT_STEP_MAP.general_investment.category)
		.sort((item1, item2) => item1.orderNo - item2.orderNo);
	const investmentBehaviorQuestions = questions
		.filter((q) => q.category === RISK_ASSESSMENT_STEP_MAP.investment_behavior.category)
		.sort((item1, item2) => item1.orderNo - item2.orderNo);
	const emotionalRiskToleranceQuestions = questions
		.filter((q) => q.category === RISK_ASSESSMENT_STEP_MAP.emotional_risk_tolerance.category)
		.sort((item1, item2) => item1.orderNo - item2.orderNo);

	const isGeneralInvestmentFilled = useMemo(
		() =>
			Object.values(questionnaire.general_investment).reduce(
				(prev, curr) => prev && curr.length !== 0,
				true
			),
		[questionnaire]
	);
	const isInvestmentBehaviorFilled = useMemo(
		() =>
			Object.values(questionnaire.investment_behavior).reduce(
				(prev, curr) => prev && curr.length !== 0,
				true
			),
		[questionnaire]
	);
	const isEmotionalRiskToleranceFilled = useMemo(
		() =>
			Object.values(questionnaire.emotional_risk_tolerance).reduce(
				(prev, curr) => prev && curr.length !== 0,
				true
			),
		[questionnaire]
	);

	const generalInvestmentStatus = useMemo(() => {
		if (currentStep === 'general_investment') return StepStatus.ACTIVE;
		return isGeneralInvestmentFilled ? StepStatus.COMPLETED : StepStatus.INACTIVE;
	}, [currentStep, isGeneralInvestmentFilled]);

	const investmentBehaviorStatus = useMemo(() => {
		if (currentStep === 'investment_behavior') return StepStatus.ACTIVE;
		return isInvestmentBehaviorFilled ? StepStatus.COMPLETED : StepStatus.INACTIVE;
	}, [currentStep, isInvestmentBehaviorFilled]);

	const emotionalRiskToleranceStatus = useMemo(() => {
		if (currentStep === 'emotional_risk_tolerance') return StepStatus.ACTIVE;
		return isEmotionalRiskToleranceFilled ? StepStatus.COMPLETED : StepStatus.INACTIVE;
	}, [currentStep, isEmotionalRiskToleranceFilled]);

	const onQuestionAnswerChange = (step: RiskAssessmentStep, questionId: string, answer: string) => {
		const answers = questionnaire[step][questionId];
		const question = questions?.find((q) => q.id === questionId);
		const { type: questionType } = question || {};

		let answersUpdated: string | string[];

		switch (questionType) {
			case QuestionType.MULTIPLE_CHOICE:
				answersUpdated = (answers as string[]).includes(answer)
					? (answers as string[]).filter((a) => a !== answer)
					: [...answers, answer];
				break;

			case QuestionType.SINGLE_CHOICE:
				answersUpdated = [answer];
				break;

			case QuestionType.OPEN:
				answersUpdated = answer;
				break;

			default:
				return;
		}

		setQuestionnaire({
			...questionnaire,
			[step]: {
				...questionnaire[step],
				[questionId]: answersUpdated,
			},
		});
	};

	const { mutate: submitAnswers, isPending: isSubmitting } = useMutation({
		mutationFn: (step: RiskAssessmentStep) => {
			const questionAnswers = questionnaire[step];
			const payload: Array<AnswerPayload> = Object.entries(questionAnswers).map(
				([questionId, answers]) =>
					Array.isArray(answers)
						? { questionId, choices: answers }
						: { questionId, answerText: answers }
			);

			return postAnswers(payload);
		},
		onSuccess: () => {
			if (
				currentQuestions.emotional_risk_tolerance ===
				Object.keys(questionnaire.emotional_risk_tolerance).length - 1
			) {
				navigate('../risk-assessment/output');
			}
		},
	});

	const isGeneralInvestmentDisabled = useMemo(
		() => isSubmitting || !isGeneralInvestmentFilled,
		[isSubmitting, isGeneralInvestmentFilled]
	);
	const isInvestmentBehaviorDisabled = useMemo(
		() => isSubmitting || !isInvestmentBehaviorFilled,
		[isSubmitting, isInvestmentBehaviorFilled]
	);
	const isEmotionalRiskToleranceDisabled = useMemo(
		() =>
			isSubmitting ||
			isGeneralInvestmentDisabled ||
			isInvestmentBehaviorDisabled ||
			!isEmotionalRiskToleranceFilled,
		[
			isSubmitting,
			isGeneralInvestmentDisabled,
			isInvestmentBehaviorDisabled,
			isEmotionalRiskToleranceFilled,
		]
	);

	if (!isQuestionsFetched || !isAnswersFetched) return <></>;

	const GENERAL_INVESTMENT_PANEL: QuestionGroupPanel = {
		id: 'general_investment',
		questionGroup: {
			questions: generalInvestmentQuestions,
			currentQuestion: currentQuestions.general_investment,
			setCurrentQuestion: (question: number) => {
				setCurrentQuestions({ ...currentQuestions, general_investment: question });
			},
			answers: questionnaire.general_investment,
			onNextStep: {
				label: 'Continue to Investment Behavior',
				callback: () => {
					if (!isFlowFinished) submitAnswers('general_investment');
					setCurrentStep('investment_behavior');
				},
				disabled: isGeneralInvestmentDisabled,
			},
			onChange: (questionId: Question['id'], answer: string) => {
				onQuestionAnswerChange('general_investment', questionId, answer);
			},
		},
	};

	const INVESTMENT_BEHAVIOR_PANEL: QuestionGroupPanel = {
		id: 'investment_behavior',
		questionGroup: {
			questions: investmentBehaviorQuestions,
			currentQuestion: currentQuestions.investment_behavior,
			setCurrentQuestion: (question: number) => {
				setCurrentQuestions({ ...currentQuestions, investment_behavior: question });
			},
			answers: questionnaire.investment_behavior,
			onNextStep: {
				label: 'Continue to Emotional Risk Tolerance',
				callback: () => {
					if (!isFlowFinished) submitAnswers('investment_behavior');
					setCurrentStep('emotional_risk_tolerance');
				},
				disabled: isInvestmentBehaviorDisabled,
			},
			onChange: (questionId: Question['id'], answer: string) => {
				onQuestionAnswerChange('investment_behavior', questionId, answer);
			},
		},
	};

	const EMOTIONAL_RISK_TOLERANCE_PANEL: QuestionGroupPanel = {
		id: 'emotional_risk_tolerance',
		questionGroup: {
			questions: emotionalRiskToleranceQuestions,
			currentQuestion: currentQuestions.emotional_risk_tolerance,
			setCurrentQuestion: (question: number) => {
				setCurrentQuestions({ ...currentQuestions, emotional_risk_tolerance: question });
			},
			answers: questionnaire.emotional_risk_tolerance,
			onNextStep: {
				label: 'Continue',
				disabled: isEmotionalRiskToleranceDisabled,
				callback: () => {
					if (isFlowFinished) {
						navigate('../risk-assessment/output');
					} else {
						submitAnswers('emotional_risk_tolerance');
					}
				},
			},
			onChange: (questionId: Question['id'], answer: string) => {
				onQuestionAnswerChange('emotional_risk_tolerance', questionId, answer);
			},
		},
	};

	return (
		<div className="flex h-full flex-col bg-neutral-page">
			<Header titleText="Risk Assessment Questionnaire" />
			<ProgressSteps
				steps={[
					{
						status: generalInvestmentStatus,
						serialNumber: 1,
						name: RISK_ASSESSMENT_STEP_MAP.general_investment.name,
						onClick: () => setCurrentStep('general_investment'),
					},
					{
						status: investmentBehaviorStatus,
						serialNumber: 2,
						name: RISK_ASSESSMENT_STEP_MAP.investment_behavior.name,
						onClick: () => setCurrentStep('investment_behavior'),
					},
					{
						status: emotionalRiskToleranceStatus,
						serialNumber: 3,
						name: RISK_ASSESSMENT_STEP_MAP.emotional_risk_tolerance.name,
						onClick: () => setCurrentStep('emotional_risk_tolerance'),
					},
				]}
			/>
			<main className="overflow-hidden" ref={scrollElementRef}>
				<QuestionGroupPanels
					panels={[
						GENERAL_INVESTMENT_PANEL,
						INVESTMENT_BEHAVIOR_PANEL,
						EMOTIONAL_RISK_TOLERANCE_PANEL,
					]}
					currentPanelId={currentStep as QuestionGroupPanel['id']}
				/>
			</main>
			<Footer withBoxShadow={false} itemsPosition="start" size="md" />
		</div>
	);
};

export default RiskAssessmentQuestionnaire;
