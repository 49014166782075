/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

// src/hooks/useSpeechToText.ts
import { useState, useEffect, useMemo } from 'react';

interface UseSpeechToText {
	transcript: string;
	isListening: boolean;
	isBrowserSupported: boolean;
	startListening: () => void;
	stopListening: () => void;
}

const useSpeechToText = (): UseSpeechToText => {
	const [isListening, setIsListening] = useState<boolean>(false); // Track whether the microphone is active
	const [isEnded, setIsEnded] = useState<boolean>(true);
	const [isBrowserSupported, setIsBrowserSupported] = useState<boolean>(false);
	const [transcript, setTranscript] = useState<string>(''); // Store the transcribed text

	// Check if SpeechRecognition is available
	const SpeechRecognition =
		(window as any).webkitSpeechRecognition || (window as any).SpeechRecognition;

	// Ensure SpeechRecognition is available
	if (!SpeechRecognition) {
		console.log('SpeechRecognition API is not supported in this browser.');
	}

	const recognition = useMemo(() => {
		if (!SpeechRecognition) return;

		const recognitionInstance = new SpeechRecognition();
		recognitionInstance.continuous = true; // Keep listening until stopped
		recognitionInstance.interimResults = true; // Show partial results while speaking
		recognitionInstance.lang = 'en-US'; // Set language to English
		// eslint-disable-next-line consistent-return
		return recognitionInstance;
	}, [SpeechRecognition]);

	useEffect(() => {
		if (!SpeechRecognition) return;

		let finalTranscript = '';
		setIsBrowserSupported(true);

		[
			'onaudiostart',
			'onaudioend',
			'onend',
			'onerror',
			'onnomatch',
			'onresult',
			'onsoundstart',
			'onsoundend',
			'onspeechend',
			'onstart',
		].forEach((eventName) => {
			// eslint-disable-next-line func-names
			recognition[eventName] = function (e: any) {
				if (eventName === 'onstart') {
					setIsListening(true);
				}

				if (eventName === 'onend') {
					setIsListening(false);
					setIsEnded(true);
				}

				if (eventName === 'onresult') {
					let interimTranscript = '';
					if (typeof e.results === 'undefined') {
						recognition.onend = null;
						recognition.stop();
						return;
					}

					for (let i = e.resultIndex; i < e.results.length; ++i) {
						if (e.results[i].isFinal) {
							finalTranscript += e.results[i][0].transcript;
						} else {
							interimTranscript += e.results[i][0].transcript;
						}
					}
					if (isListening) setTranscript(finalTranscript || interimTranscript);
				}

				if (eventName === 'onerror') {
					console.error('Speech recognition error', e);
				}
			};
		});
	}, [isListening, recognition, SpeechRecognition]);

	const stopListening = () => {
		if (isListening) {
			recognition.abort();
			setIsListening(false);
			recognition.continuous = false;
		}
	};

	const startListening = () => {
		recognition.continuous = true;
		if (!isListening && isEnded) {
			recognition.start();
			setIsEnded(false);
		} else {
			setIsListening(true);
		}
	};

	return {
		transcript,
		isListening,
		isBrowserSupported,
		startListening,
		stopListening,
	};
};

export default useSpeechToText;
