import { forwardRef, ReactElement, useEffect, useRef } from 'react';
import { TextField } from '@/components/ui/inputs';
import QuestionAbstract, { QuestionAbstractProps } from './QuestionAbstract';
import useSpeechToText from '../question-group/useSpeechToText';
import ButtonPrimary from '@/components/ui/button/button-primary/ButtonPrimary';
import { Microphone } from '@/assets/icons/Microphone';
import '@/scss/_question-open.scss';

export interface QuestionOpenProps extends Omit<QuestionAbstractProps, 'children'> {
	answer: string;
	onChange: (questionId: string, answer: string) => void;
	onPressEnter?: () => void;
	placeholder?: string;
	showInfo?: boolean;
	isFocused?: boolean;
	classes?: string[];
}

export const QuestionOpen = forwardRef<HTMLDivElement, QuestionOpenProps>(
	(
		{
			question,
			answer,
			onChange,
			onPressEnter,
			placeholder,
			showInfo,
			isFocused,
			classes = [],
			...questionAbstractProps
		},
		ref
	): ReactElement => {
		const textInputRef = useRef<HTMLTextAreaElement>(null);
		const { transcript, isListening, isBrowserSupported, startListening, stopListening } =
			useSpeechToText();

		// Control focused state
		useEffect(() => {
			if (isFocused) {
				textInputRef.current?.focus({ preventScroll: true });
			} else {
				textInputRef.current?.blur();
			}
		}, [isFocused]);

		const onInputChange = (text: string) => {
			onChange(question.id, text);
		};

		useEffect(() => {
			if (!isBrowserSupported) return;
			onInputChange(transcript);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [transcript, isBrowserSupported]);

		return (
			<QuestionAbstract {...{ ...questionAbstractProps, question }} ref={ref}>
				<TextField
					ref={textInputRef}
					id={`${question.id}-question`}
					name={`${question.id}-question`}
					value={answer}
					onChange={(answer) => onInputChange(answer)}
					onPressEnter={onPressEnter}
					placeholder={placeholder}
					showInfo={showInfo}
					classes={classes}
				>
					{isBrowserSupported &&
						(!isListening ? (
							<ButtonPrimary
								size="md"
								className="start-speech-btn"
								icon={<Microphone />}
								onClick={startListening}
								type="button"
							/>
						) : (
							<ButtonPrimary
								size="md"
								className="stop-speech-btn"
								onClick={stopListening}
								type="button"
							>
								<div className="h-[14px] w-[14px] rounded-sm bg-white"></div>
							</ButtonPrimary>
						))}
				</TextField>
			</QuestionAbstract>
		);
	}
);

QuestionOpen.displayName = 'Question Open';

export default QuestionOpen;
