import { forwardRef, KeyboardEvent, ReactElement, ReactNode } from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import WrapperInput from '../../wrapper-input';
import { TextInputProps } from '../text-input.types';
import '../text-input.scss';
import { classNames } from '@/lib/utils';

interface TextFieldProps extends TextInputProps {
	onPressEnter?: () => void;
	showInfo?: boolean;
	children?: ReactNode;
	classes?: string[];
}

const TextField = forwardRef<HTMLTextAreaElement, TextFieldProps>(
	(
		{
			id,
			name,
			value,
			onChange,
			onPressEnter,
			placeholder,
			showInfo = true,
			error,
			children,
			classes = [],
		},
		ref
	): ReactElement => {
		const onEnter = (event: KeyboardEvent<HTMLTextAreaElement>) => {
			if (event.key === 'Enter' && !event.shiftKey) {
				event.preventDefault();
				onPressEnter?.();
			}
		};

		return (
			<div className="text-input__wrapper">
				<WrapperInput error={error}>
					<div className="relative flex flex-auto">
						<ReactTextareaAutosize
							id={id}
							name={name}
							value={value}
							onChange={(e) => onChange?.(e.target.value)}
							placeholder={placeholder}
							className={classNames('text-input', ...classes)}
							onKeyDown={(e) => onEnter(e)}
							ref={ref}
						/>
						<div className="absolute bottom-[7px] right-0">{children}</div>
					</div>
				</WrapperInput>
				{showInfo && (
					<small className="text-input__info">
						<strong>Shift &#8679; + Enter &crarr;</strong> to make a line break
					</small>
				)}
			</div>
		);
	}
);

TextField.displayName = 'Text Field';

export default TextField;
